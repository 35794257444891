* {
  margin: 0;
  padding: 0;
}

wrapper {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.links {
  list-style: none;
}

.links li {
  /* width: 50px;
  height: 50px; */
  position: relative;

  margin: 2px 0;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

ul li .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.slider {
  content: "";
  position: absolute;
  top: 0;
  right: 50px;
  width: 50px;
  height: 30px;
  background: transparent;

  border-radius: 50px;
  transition: all 0.5s 0.3s ease;
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
  /* padding-left: 5px; */
}

.slider p {
  font-family: arial;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  line-height: 50px;
  opacity: 0;
  transition: all 0.6s ease;
  margin-left: 10px;
}

ul li:hover .slider {
  width: 180px;
  transition: all 0.5s ease;
  background: rgba(253, 250, 250, 0.204);
  /* right: 50px; */
}

ul li:hover .slider p {
  opacity: 1;
  transition: all 1s 0.2s ease;
}
