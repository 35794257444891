@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Hahmlet:wght@600&family=Inknut+Antiqua:wght@300&family=Open+Sans:wght@300&family=Pacifico&family=Roboto:wght@100&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
}

.color {
  background: linear-gradient(
      6deg,
      rgba(14, 35, 52, 0.2) 0%,
      rgba(26, 57, 77, 0.2) 20.83%,
      rgba(44, 81, 101, 0.2) 42.47%,
      rgba(112, 177, 196, 0.2) 100%
    ),
    linear-gradient(
      24deg,
      #091b29 0%,
      #0a1c2b 18.23%,
      #102637 34.18%,
      #1b3748 48.11%,
      #2c5567 63.03%,
      #71adc1 100%
    );
}

.head {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(255, 255, 255, 0.83);
}

.selector {
  background-image: radial-gradient(
    at bottom left,
    #091b29,
    #0a1c2b,
    #102637,
    #1b3748,
    #2c5567,
    #71adc1
  );
}
.navbar {
  background: linear-gradient(to right, #1b3748, #2c5567, #71adc1);
}

.hero {
  color: linear-gradient(90deg, #e0c274 0%, #e6da94 45.84%, #dfc275 100%);
}

.carousel-slide {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  border-radius: 8px;
}

.font {
  font-family: Pacifico, cursive;
}

.hero_font {
  font-family: Inknut Antiqua;
}

.hero_price_font {
  font-family: Bodoni;
}

.content-font {
  font-family: PT Serif;
}

.content-head {
  font-family: hahmlet;
}
.slide-head {
  font-family: Abhaya Libre;
}

.about_color {
  background: linear-gradient(102deg, #0b1d2c 0%, #1a3446 100%);
}

.about_head_color {
  background: linear-gradient(
    126deg,
    rgba(21, 57, 84, 0.54) 0%,
    rgba(49, 96, 122, 0.03) 53.13%,
    rgba(114, 162, 180, 0) 100%
  );
}

.hero_bg {
  background: linear-gradient(to top, #ffb800, #ffffff);
}

.about_color {
  color: var(--hot-red-purple, #fff);
}
